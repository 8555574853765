import React, { useEffect } from 'react';
import * as d3 from 'd3';

const hashStringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
    }
    const r = (hash & 0xFF0000) >> 16;
    const g = (hash & 0x00FF00) >> 8;
    const b = (hash & 0x0000FF);
    return `rgb(${r}, ${g}, ${b})`;
};

const createPlot = (datasets, elementId, title) => {
    const margin = { top: 30, right: 30, bottom: 70, left: 80 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const svg = d3.select(elementId)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleLinear()
        .domain([d3.min(datasets, d => d3.min(d.steps)), d3.max(datasets, d => d3.max(d.steps))])
        .range([0, width]);

    const yScale = d3.scaleLinear()
        .domain([d3.min(datasets, d => d3.min(d.data)), d3.max(datasets, d => d3.max(d.data))])
        .range([height, 0]);

    for (const dataset of datasets) {
        console.log("dataset", dataset);
        const color = dataset.isYs ? "red" : hashStringToColor(dataset.jobDir);
        const line = d3.line()
            .x((d, i) => xScale(dataset.steps[i]))
            .y(d => yScale(d));

        const path = svg.append("path")
            .datum(dataset.data)
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", 1.5)
            .attr("d", line)
            .attr("class", `line-${dataset.jobDir}`);

        const circles = svg.selectAll(`.circle-${dataset.jobDir}`)
            .data(dataset.data)
            .enter()
            .append("circle")
            .attr("cx", (d, i) => xScale(dataset.steps[i]))
            .attr("cy", d => yScale(d))
            .attr("r", 3)
            .attr("fill", color)
            .attr("class", `circle-${dataset.jobDir}`);
    };

    svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale));

    svg.append("g")
        .call(d3.axisLeft(yScale));

    // Add X axis label
    svg.append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom - 20)
        .text("Time");

    // Add Y axis label
    svg.append("text")
        .attr("class", "y label")
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        .attr("y", -margin.left + 20)
        .attr("x", -height / 2)
        .attr("dy", "1em")
        .text("Hz");

    // Add title
    svg.append("text")
        .attr("x", (width / 2))
        .attr("y", 10 - (margin.top / 2))
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .style("text-decoration", "underline")
        .text(title);

    // Add tooltips
    const tooltip = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

    svg.selectAll("circle")
        .on("mouseover", function (event, d) {
            tooltip.transition()
                .duration(200)
                .style("opacity", .9);
            tooltip.html(`Epoch: ${d.epoch}<br/>Value: ${d.value}`)
                .style("left", (event.pageX + 5) + "px")
                .style("top", (event.pageY - 28) + "px");
        })
        .on("mouseout", function (d) {
            tooltip.transition()
                .duration(500)
                .style("opacity", 0);
        });
};

const Plot = ({ datasets, elementId, title }) => {
    useEffect(() => {
        createPlot(datasets, `#${elementId}`, title);
    }, [datasets, elementId, title]);

    return <div id={elementId}></div>;
};

export { createPlot, hashStringToColor };
export default Plot;
