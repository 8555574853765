import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';

const BranchList = () => {
  const { project } = useParams();
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    fetch(`${config.apiUrl}/${project}`)
      .then(response => response.json())
      .then(data => setBranches(data.branches));
  }, [project]);

  return (
    <div>
      <h1>Branch List for {project}</h1>
      <ul>
        {branches.map(branch => (
          <li key={branch}>
            <a href={`/${project}/${branch}`}>{branch}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BranchList;
