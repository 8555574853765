import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';

const CommitList = () => {
  const { project, branch } = useParams();
  const [commits, setCommits] = useState([]);

  useEffect(() => {
    fetch(`${config.apiUrl}/${project}/${branch}`)
      .then(response => response.json())
      .then(data => setCommits(data.commits));
  }, [project, branch]);

  return (
    <div>
      <h1>Commit List for {branch} in {project}</h1>
      <ul>
        {commits.map(commit => (
          <li key={commit}>
            <a href={`/${project}/${branch}/${commit}`}>{commit}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommitList;
