import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as d3 from 'd3';
import PlotPtFiles from './PlotPtFiles';
import PlotLosses from './PlotLosses';
import MetricsTable from './MetricsTable';
import config from '../config';

const Checkpoint = () => {
  const { project, branch, commit, job } = useParams();
  const [checkpointData, setCheckpointData] = useState(null);

  useEffect(() => {
    fetch(`${config.apiUrl}/${project}/${branch}/${commit}/${job} `)
      .then(response => response.json())
      .then(data => {
        setCheckpointData(data);
      });
  }, [project, branch, commit, job]);


  if (!checkpointData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Checkpoint Data for Job {job}</h1>
      <div>
        <h2>HTML Table</h2>
        <div dangerouslySetInnerHTML={{ __html: checkpointData.html_table }} />
        <MetricsTable metrics={checkpointData.metrics} />
        <h2>Best Checkpoint</h2>
        <pre>{checkpointData.best_checkpoint}</pre>
        <PlotLosses trainLogsContent={checkpointData.train_logs} valLogsContent={checkpointData.val_logs} jobDir={job} />
        <PlotPtFiles ptFilesContent={checkpointData.output_recon} ysFilesContent={checkpointData.orig_input} jobDir={job} plotName="Reconstructed curves" />
        <PlotPtFiles ptFilesContent={checkpointData.logsdir_yhats} ysFilesContent={checkpointData.logsdir_ys} jobDir={job} plotName="Training phase dev set curves" />
      </div>
    </div>
  );
};

export default Checkpoint;
