import React from 'react';

const MetricsTable = ({ metrics }) => {
    if (!metrics || Object.keys(metrics).length === 0) {
        return <div>No metrics available</div>;
    }


    const allKeys = new Set();
    Object.values(metrics).forEach(value => {
        Object.keys(value).forEach(key => allKeys.add(key));
    });

    return (
        <div>
            <h2>Metrics</h2>
            <table border="1">
                <thead>
                    <tr>
                        <th>Metric</th>
                        {[...allKeys].map(key => (
                            <th key={key}>{"Channel "+key}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(metrics).map(([metric, value]) => (
                        <tr key={metric}>
                            <td>{metric}</td>
                            {[...allKeys].map(key => (
                                <td key={key}>{value[key] !== undefined ? value[key].toFixed(4) : ''}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MetricsTable;
