import React, { useEffect } from 'react';
import Plot from './Plot';

const plotLosses = (trainLogsContent, valLogsContent, jobDir) => {
    const trainDataset = {
        data: Object.values(trainLogsContent),
        steps: Object.keys(trainLogsContent).map(Number),
        jobDir: jobDir
    };

    const valDataset = {
        data: Object.values(valLogsContent),
        steps: Object.keys(valLogsContent).map(Number),
        jobDir: jobDir
    };

    return { trainDataset, valDataset };
};

const PlotLosses = ({ trainLogsContent, valLogsContent, jobDir }) => {
    const { trainDataset, valDataset } = plotLosses(trainLogsContent, valLogsContent, jobDir);

    return (
        <div>
            <Plot datasets={[trainDataset]} elementId="train-loss-plot" title="Train Loss" />
            <Plot datasets={[valDataset]} elementId="val-loss-plot" title="Validation Loss" />
        </div>
    );
};

export default PlotLosses;
