import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProjectList from './components/ProjectList';
import BranchList from './components/BranchList';
import CommitList from './components/CommitList';
import JobList from './components/JobList';
import Checkpoint from './components/Checkpoint';
import ValidationErrorPlot from './components/ValidationErrorPlot';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={ProjectList} />
        <Route path="/:project" exact component={BranchList} />
        <Route path="/:project/:branch" exact component={CommitList} />
        <Route path="/:project/:branch/:commit" exact component={JobList} />
        <Route path="/:project/:branch/:commit/:job" exact component={Checkpoint} />
      </Switch>
    </Router>
  );
};

export default App;
