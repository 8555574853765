import React, { useEffect } from 'react';
import Plot from './Plot';

const PlotPtFiles = ({ ptFilesContent, ysFilesContent, jobDir, plotName = "Graphs" }) => {
    const plots = [];

    for (const [fileName, ptData] of Object.entries(ptFilesContent)) {
        const ysData = ysFilesContent[fileName];
        if (ysData) {
            ptData.forEach((channelData, index) => {
                const steps = Array.from({ length: channelData.length }, (_, i) => i);
                const ptDataset = { data: channelData, steps, jobDir: jobDir, isYs: false };
                const ysDataset = { data: ysData[index], steps, jobDir: jobDir, isYs: true };
                plots.push({ datasets: [ptDataset, ysDataset], title: `${fileName} - Channel ${index + 1}` });
            });
        }
    }

    return (
        <div>
            <h3>{plotName}</h3>
            {plots.map((plot, index) => (
                <Plot key={index} datasets={plot.datasets} elementId={`plot-${index}`} title={plot.title} />
            ))}
        </div>
    );
};

export default PlotPtFiles;
